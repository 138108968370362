<template>
  <el-dialog
      class="el-dialog-cus"
      v-bind="attributes"
      :visible.sync="visible"
      append-to-body
  >
    <slot v-if="visibleSlot"></slot>
    <span
        v-if="diaText"
        class="diaText"
        :style="attributes.txtcenter ? 'text-align: center' : ''">{{ diaText }}</span>
    <div slot="footer" style="text-align: center;justify-content: center" class="flexAndCenter">
      <el-button
          @click="
          queRen();
          cancel();
        "
          v-if="btnTxt[0]"
          type="primary"
      >{{ btnTxt[0] }}
      </el-button>
      <el-button
          v-if="btnTxt[1]"
          @click="
          quXiao();
          cancel();
        "
          plain
      >{{ btnTxt[1] }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    config: Object,
  },
  data() {
    const {
      top = "20vh",
      width = "480px",
      title = "提示",
      btnTxt = [this.$fanyi("确定"), this.$fanyi("取消")],
    } = this.config || {};
    return {
      visible: false,
      attributes: {
        top,
        width,
        title,

        ...this.config,
      },
      btnTxt,
      visibleSlot: false,
      queRen: () => {
      },
      quXiao: () => {
      },
      diaText: null,
    };
  },
  created() {
    document.onkeydown = (e) => {
      let body = document.getElementsByTagName("body")[0];
      if (e.keyCode === 13 && e.target === body && this.visible == true) {
        this.queRen();
        this.cancel();
      }
    };
  },
  methods: {
    open(text, queRen, quXiao) {
      this.diaText = text;
      queRen ? (this.queRen = queRen) : "";
      quXiao ? (this.quXiao = quXiao) : "";
      this.visible = true;
      this.visibleSlot = true;
      return this.$nextTick();
    },
    cancel() {
      this.visible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/mixin.scss";

.el-dialog-cus {
  /deep/ .el-dialog {
    border-radius: 4px;
  }

  /deep/ .el-dialog__title {
    line-height: 36px;
    font-size: 24px;
  }

  /deep/ .el-dialog__header {
    border-bottom: solid 1px #ededed;
    min-height: 60px;
  }

  /deep/ .el-dialog__headerbtn {
  }

  /deep/ .el-dialog__body {
    padding: 40px 30px;
  }

  /deep/ .el-dialog__footer {
    .el-button {
      padding: 14px 50px;
      border-radius: 4px;
      font-size: 16px;
      margin: 0;

      &:first-child {
        color: white !important;
        margin: 0 !important;
      }

      &:last-child {
        margin-left: 80px;
        color: $homePageSubjectColor;
      }
    }
  }

  .diaText {
    font-size: 18px;
    color: #000000;
    line-height: 26px;

    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>