<template>
  <div>
    <Dialog ref="dialog" :config="config"></Dialog>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("中国国内物流状态") }}</div>
      </div>
      <!--      中国国内物流状态切换-->
      <div class="tabsContainer flex" style="margin: 26px 26px 0 26px">
        <el-tabs v-model="form.status" type="card" @tab-click="form.page=1;getData(form)">
          <el-tab-pane :label="allText" name="全部"></el-tab-pane>
          <el-tab-pane :label="purchaseText" name="等待购买"></el-tab-pane>
          <el-tab-pane :label="shipmentsText" name="待发货"></el-tab-pane>
          <el-tab-pane :label="purchasedText" name="国内派送中"></el-tab-pane>
          <el-tab-pane :label="shippedText" name="已签收"></el-tab-pane>
          <el-tab-pane :label="putawayText" name="上架中"></el-tab-pane>
          <!--          <el-tab-pane :label="putawayedText" name="上架完成"></el-tab-pane>-->
        </el-tabs>
        <img src="../../../../assets/icon/sort.png" alt="" @click="updateGoodsSort" class="sortIcon">
        <img alt="" class="sortIcon" src="../../../../assets/downloadIcon.png" style="left: 900px"
             @click="downloadIconExcel">
        <div class="tabsRightContainer"></div>
      </div>
      <!--      中国国内物流订单筛选-->
      <div class="flexAndCenter orderScreenContainer">
        <div class="orderKeywordsContainer flexAndCenter">
          <div class="fontSize14 color333" style="width:71px">{{ $fanyi("订单号") }}：</div>
          <el-input v-model="form.order_sn" style="width: 200px;" :placeholder="$fanyi('请输入订单号搜索')"/>
        </div>
        <div class="orderScreenBtnListContainer flexAndCenter" style="margin-left:26px">
          <div @click="getData(form)">{{ $fanyi("筛选") }}</div>
          <div @click="orderReset">{{ $fanyi("重置") }}</div>
        </div>
      </div>
      <div class="dataBody">
        <ul class="head flexAndCenter">
          <li class="oNO"><span class="tableHeadCon">No</span></li>
          <li class="goodsImg">
            <span class="tableHeadCon" :title="$fanyi('商品照片')">{{ $fanyi("商品照片") }}</span>
          </li>
          <li class="goodsAttribute">
            <span class="tableHeadCon" :title="$fanyi('商品属性')">{{ $fanyi("商品属性") }}</span>
          </li>
          <li class="goodsImg">
            <span class="tableHeadCon" :title="$fanyi('购买数量')">{{ $fanyi("购买数量") }}</span>
          </li>
          <li class="goodsImg">
            <span :title="$fanyi('已上架数量')" class="tableHeadCon">{{ $fanyi("已上架数量") }}</span>
          </li>
          <li class="goodsImg">
            <span class="tableHeadCon" :title="$fanyi('单价.元')">{{ $fanyi("单价.元") }}</span>
          </li>
          <li class="subtotal">
            <span class="tableHeadCon" :title="$fanyi('小计.元')">{{ $fanyi("小计.元") }}</span>
          </li>
          <li class="goodsImg">
            <span class="tableHeadCon" :title="$fanyi('国内运费')">{{ $fanyi("国内运费") }}</span>
          </li>
          <li class="remarks">
            <span class="tableHeadCon" :title="$fanyi('备注栏')">{{ $fanyi("备注栏") }}</span>
          </li>
          <li class="goodsAttribute">
            <span class="tableHeadCon" :title="$fanyi('状态')">{{ $fanyi("状态") }}</span>
          </li>
        </ul>
        <!--      中国国内物流订单筛选-->
        <div>
          <div v-for="(item,index) in tableData" :key="index">
            <div class="newHead flexAndCenter" style="justify-content: space-between">
              <div class="flexAndCenter">
                <input v-model="item.checked" style="margin-right: 6px" type="checkbox" @change="inputChange"/>
                <div class="orderNumber flexAndCenter">{{ $fanyi("订单号") }}：
                  <router-link :to="{path:'/OrderDetails',query: {order_sn: item.order_sn}}"
                               class="colorB4272B cursorPointer"
                               style="text-decoration: underline;" target="_blank">
                    {{ item.order_sn }}
                  </router-link>
                  <el-popover v-if="item.children_user!=null" placement="bottom" trigger="hover">
                    <div>
                      <div>社員用アカウント情報</div>
                      <div>氏名：{{ item.children_user.children_realname }}</div>
                    </div>
                    <img slot="reference"
                         class="cursorPointer"
                         src="../../../../assets/subAccount.png"
                         style="margin-top: 10px;margin-left: 5px"
                    />
                  </el-popover>
                </div>
                <!--              下单数量-->
                <div class="goodsImg" style="width: 87.93px">{{ item.confirm_num }}</div>
                <!--              下单数量-->
                <div class="goodsImg" style="width: 87.93px"></div>
                <!--              単価-->
                <div class="goodsImg" style="width: 87.93px"></div>
                <!--              小计-->
                <div class="goodsImg" style="width: 105.52px">{{ Number(item.total).toFixed(2) }}</div>
                <!--              国内运费-->
                <div class="subtotal" style="width: 87.93px">{{ Number(item.confirm_freight).toFixed(2) }}</div>
              </div>
              <!--              切换列表展示或隐藏的按钮-->
              <div style="width: 175px;display: flex;justify-content: center">
                <div class="showStatusBtn" @click="item.showStatus=!item.showStatus;$forceUpdate()">
                  <i :class="item.showStatus === true?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                  <div>{{ item.showStatus === true ? $fanyi("隐藏") : $fanyi("展开") }}</div>
                </div>
              </div>
            </div>
            <ul class="footer flexAndCenter" v-for="(newItem,newIndex) in item.order_detail"
                v-show="item.showStatus===true" :key="newIndex">
              <!--              No-->
              <li class="oNO"><span class="tableHeadCon">{{ newItem.sorting }}</span></li>
              <!--              商品照片-->
              <li class="goodsImg" style="flex-direction: column">
                <el-popover placement="right" trigger="hover">
                  <img :src="newItem.pic"
                       style="width: 300px;height: 300px"
                  />
                  <img :src="newItem.pic"
                       @click="$fun.toCommodityDetails(newItem.goods_id,newItem.from_platform)"
                       style="width: 60px;height: 60px"
                       slot="reference"
                  />
                </el-popover>
                <el-popover placement="bottom" trigger="hover">
                  <div>{{ $fanyi("手续费") }}：
                    {{ $fun.returnServiceRate(newItem.service_rate) }}%
                  </div>
                  <div slot="reference">{{ newItem.from_platform }}</div>
                </el-popover>
              </li>
              <!--              商品属性-->
              <li class="goodsAttribute">
                <el-popover placement="bottom" trigger="hover">
                  <div class="showDetailCon">
                    <p v-for="(bitem, bindex) in newItem.detail"
                       :key="bitem.key + bindex"
                       class="goodsDetailOpt ispay">
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                  <div class="showDetailCon" style="width:160px" slot="reference">
                    <p v-for="(bitem, bindex) in newItem.detail"
                       :key="bitem.key + bindex"
                       class="goodsDetailOpt ispay">
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                </el-popover>
              </li>
              <!--              下单数量-->
              <li class="goodsImg">
                {{ newItem.confirm_num }}
              </li>
              <!--              上架数量-->
              <li class="goodsImg">
                {{ newItem.storage_num }}
              </li>
              <!--              単価-->
              <li class="goodsImg">
                {{ Number(newItem.confirm_price) }}
              </li>
              <!--              小计-->
              <li class="subtotal" style="flex-direction: column;">
                <div style="padding-top: 20px">
                  {{ Number(newItem.total).toFixed(2) }}
                </div>
                <el-popover trigger="hover" width="270">
                  <div style="width: 250px">
                    <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                      <div>{{ $fanyi('商品金額.元') }}</div>
                      <div>{{ Number(newItem.confirm_num * newItem.confirm_price).toFixed(2) }}元（{{
                          $fun.yenNumSegmentation(Number(Number(newItem.confirm_num * newItem.confirm_price) * Number(item.exchange_rate
                          )).toFixed(0))
                        }}円）
                      </div>
                    </div>
                    <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                      <div>{{ $fanyi('国内送料.元') }}</div>
                      <div>{{ newItem.confirm_freight }}元（{{
                          $fun.yenNumSegmentation(Number(Number(newItem.confirm_freight) * Number(item.exchange_rate)).toFixed(0))
                        }}円）
                      </div>
                    </div>
                    <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                      <div>{{ $fanyi('手数料') }}({{ $fun.returnServiceRate(newItem.service_rate) }}%)</div>
                      <div>{{ Number(newItem.service_rate_value).toFixed(2) }}元（{{
                          $fun.yenNumSegmentation(Number(Number(newItem.service_rate_value) * Number(item.exchange_rate)).toFixed(0))
                        }}円）
                      </div>
                    </div>
                    <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                      <div>{{ $fanyi('オプション手数料.元') }}</div>
                      <div>{{ newItem.optionPrice }}元
                        （{{
                          $fun.yenNumSegmentation(Number(Number(newItem.optionPrice) * Number(item.exchange_rate)).toFixed(0))
                        }}円）
                      </div>
                    </div>
                  </div>
                  <div slot="reference" class="quantityOrderedDetailsText colorB4272B cursorPointer">
                    {{
                      $fanyi('详细')
                    }}
                  </div>
                </el-popover>
              </li>
              <!--              国内运费-->
              <li class="goodsImg">
                {{ Number(newItem.confirm_freight) }}
              </li>
              <!--              备注栏-->
              <li class="remarks">
                <el-popover trigger="hover" v-if="newItem.client_remark!==''">
                  <div>{{ newItem.client_remark }}</div>
                  <div class="hoverText" slot="reference">{{ $fanyi('查看') }}</div>
                </el-popover>
                <div class="hoverText" v-else>{{ $fanyi('查看') }}</div>
              </li>
              <!--              状态-->
              <li class="goodsAttribute">
                <div>
                  <div class="hoverText" @click="orderLogisticsStatusPageJump(item,newItem)">
                    {{
                      $fanyi(newItem.status_name)
                    }}
                  </div>
                  <div v-if="newItem.problem_goods_status != null" class="hoverText" style="margin-top: 2px">
                    {{ newItem.problem_goods_status }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style="padding: 30px 0;justify-content: center" class="flexAndCenter pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../components/public/Dialog";
import axios from "axios";

export default {
  data() {
    return {
      form: {
        status: '全部',
        page: 1,
        pageSize: 20,
        order_sn: '',
        order_by: 'desc',
      },
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      total: 0,
      purchasedText: this.$fanyi('派送中') + '（0）',
      putawayText: this.$fanyi('商品作业中') + '（0）',
      purchaseText: this.$fanyi('待采购') + '（0）',
      allText: this.$fanyi('全部') + '（0）',
      shippedText: this.$fanyi('已到达rakumart仓库') + '（0）',
      putawayedText: this.$fanyi('上架完成') + '（0）',
      shipmentsText: this.$fanyi('待发货') + '（0）',
      tableData: [],
      newList: [],
      loadingStatus: false,
      timeValue: [],
      orderList: [],
      loading: null,
    };
  },
  components: {
    Dialog
  },
  computed: {},
  created() {
    if (this.$route.query.status) {
      switch (this.$route.query.status) {
        case 'purchase':
          this.form.status = '等待购买';
          break;
        case 'purchased':
          this.form.status = '国内派送中';
          break;
        case 'putaway':
          this.form.status = '上架中';
          break;
        case 'shipped':
          this.form.status = '已签收';
          break;
      }
    }
    this.getData(this.form);
    this.ordersSum();
  },
  methods: {
    // 获取数据
    getData(data) {
      this.tableData = [];
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.loadingStatus = true;
      this.$api.chinaLogisticsList(data).then((res) => {
        if (res.code != 0) {
          this.loading.close();
          this.loadingStatus = false;
          return
        }
        res.data.data.forEach((item) => {
          item.confirm_freight = 0;
          item.checked = false;
          item.confirm_num = 0;
          item.confirm_price = 0;
          item.total = 0;
          item.showStatus = true;
          item.order_detail.forEach((items) => {
            items.optionPrice = 0;
            if (items.option != null) {
              if (items.option.length > 0) {
                items.option.forEach((optionItem) => {
                  if (optionItem.checked === true && optionItem.num >= 1 && optionItem.price_type === 0) {
                    items.optionPrice += optionItem.num * Number(optionItem.price);
                  }
                  if (optionItem.checked === true && optionItem.price_type === 1) {
                    this.optionTotalPrice += (Number(items.confirm_price) * items.confirm_num) * (Number(optionItem.price) / 100);
                  }
                })
              }
            }
            item.confirm_num += Number(items.confirm_num);
            item.confirm_freight += Number(items.confirm_freight);
            items.service_rate_value = (items.confirm_price * items.confirm_num) * Number(items.service_rate);
            item.total += Number(items.confirm_num * items.confirm_price) + Number(items.confirm_freight) + items.service_rate_value + items.optionPrice;
            items.total = Number(items.confirm_num * items.confirm_price) + Number(items.confirm_freight) + items.service_rate_value + items.optionPrice;
          })
        })
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.form.page = res.data.currentPage;
        this.loading.close();
        this.loadingStatus = false;
        this.$forceUpdate();
      })
    },
    updateGoodsSort() {
      this.form.order_by === 'asc' ? this.form.order_by = 'desc' : this.form.order_by = 'asc';
      this.getData(this.form);
    },
    //中国国内物流追踪页面跳转
    orderLogisticsStatusPageJump(item, childrenItem) {
      window.open(`/orderLogisticsStatus?order_sn=${item.order_sn}&order_detail_id=${childrenItem.id}`)
    },
    //勾选数量10个超出提示
    inputChange() {
      let count = 0;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].checked) {
          count++;
          if (count > 10) {
            count++;
            this.$nextTick(() => {
              this.tableData[i].checked = false;
              this.$forceUpdate();
            })
            this.$message.error(this.$fanyi('一次最多只能导出10个订单'))
            break;
          }
        }
      }
    },
    //excel导出
    downloadIconExcel() {
      let arr = [];
      this.tableData.forEach((item) => {
        if (item.checked) {
          arr.push(item.order_sn)
        }
      })
      if (this.total > 10 && arr.length > 10) return this.$message.error(this.$fanyi('一次最多只能导出10个订单'))
      if (this.total > 10 && arr.length == 0) return this.$message.error(this.$fanyi('导出表格请至少选择一个订单'))
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("数据导出中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      let url = location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1 ? 'https://apiwww.rakumart.com/client/logistics.chinaLogistics.list' : 'https://jpapi.rakumart.cn/client/logistics.chinaLogistics.list';
      let user_token = localStorage.getItem('japan_user_token')
      axios.post(url, {
        status: this.form.status,
        export: 1,
        order_sn_set: arr.length > 0 ? arr : undefined,
      }, {
        responseType: 'blob',
        headers: {'Content-type': 'application/x-www-form-urlencoded', 'ClientToken': 'Bearer ' + user_token},
      }).then((res) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(res);
        link.download = `中国国内物流状態エクスポート`;
        link.click();
        window.URL.revokeObjectURL(link.href);
        this.loading.close();
      })
    },
    // 获取每个状态数量
    ordersSum() {
      this.$api.chinaLogisticsListTableNum().then((res) => {
        if (res.data.length == 0) return (this.allText = this.$fanyi('全部') + '（0）');
        res.data.forEach((item) => {
          switch (item.name) {
            case '等待购买':
              this.purchaseText = this.$fanyi('待采购') + `（${item.count}）`;
              break;
            case '国内派送中':
              this.purchasedText = this.$fanyi('派送中') + `（${item.count}）`;
              break;
            case '上架中':
              this.putawayText = this.$fanyi('商品作业中') + `（${item.count}）`;
              break;
            case '全部':
              this.allText = this.$fanyi('全部') + `（${item.count}）`;
              break;
            case '已签收':
              this.shippedText = this.$fanyi('已到达rakumart仓库') + `（${item.count}）`;
              break;
            case '待发货':
              this.shipmentsText = this.$fanyi('待发货') + `（${item.count}）`;
              break;
            case '上架完成':
              this.putawayedText = this.$fanyi('上架完成') + `（${item.count}）`;
              break;
          }
        })
      })
    },
    //筛选重置
    orderReset() {
      this.form = {
        status: '全部',
        page: 1,
        pageSize: 10,
        order_sn: '',
      };
      this.getData(this.form);
    },
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData(this.form)
    },
    //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form)
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";

/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .questionMark {
      width: 25px;
      height: 25px;
      border: 1px solid #B4272B;
      border-radius: 50%;
      color: #B4272B;
      cursor: pointer;
      text-align: center;
      line-height: 25px;
      font-size: 18px;
      margin-left: 11px;
    }
  }

  .tabsContainer {
    position: relative;
    overflow: hidden;

    .sortIcon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      left: 850px;
    }

    .tabsRightContainer {
      flex: 1;
      height: 40px;
      border-bottom: 1px solid #B4272B;
      width: 100%;
    }

    /deep/ .el-tabs__header {
      border-bottom: none;
    }

    /deep/ .is-active {
      border-top: 1px solid #B4272B !important;
      border-right: 1px solid #B4272B !important;
      border-left: 1px solid #B4272B !important;
      border-bottom: 1px solid transparent !important;
    }

    /deep/ .el-tabs__item {
      border-radius: 10px 10px 0 0;
      border-top: 1px solid #DFDFDF;
      border-left: 1px solid #DFDFDF;
      border-right: 1px solid #DFDFDF;
      border-bottom: 1px solid #B4272B;
    }

    /deep/ .el-tabs__nav {
      border: none;
    }
  }

  .orderScreenContainer {
    margin-left: 26px;
    padding-bottom: 15px;
    margin-right: 26px;
    border-bottom: 1px solid #DFDFDF;

    .orderKeywordsContainer {
      /deep/ .el-input__inner {
        width: 200px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
      }
    }

    .orderTimeContainer {
      /deep/ .el-input__inner {
        width: 160px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
        padding-left: 13px;
        padding-right: 0;
      }

      /deep/ .el-date-editor {
        .el-input__prefix {
          display: none;
        }
      }
    }

    .orderScreenBtnListContainer {
      div {
        width: 76px;
        height: 30px;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
      }

      div:first-child {
        color: #fff;
        background: #B4272B;
        margin-right: 10px;
      }

      div:last-child {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }
  }

  .dataBody {
    margin: 20px 26px 0;

    .oNO {
      width: 60px;
    }

    .hoverText {
      color: #B4272B;
      text-decoration: underline;
      cursor: pointer;
    }

    .goodsImg {
      width: 100px;
    }

    .remarks {
      width: 122px;
    }

    .goodsAttribute {
      width: 200px;

      .showDetailCon {
        width: 200px;
        text-align: left;

        .ispay {
          width: 160px;
          height: 20px;
          line-height: 20px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .newHead {
      background: #fff;
      height: 60px;
      font-size: 14px;

      .orderNumber {
        width: 316px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }

      .goodsImg {
        text-align: center;
      }

      .newGodsImg {
        width: 100px;
        text-align: center;
        margin-right: 160px;
      }

      .showStatusBtn {
        width: 90px;
        height: 30px;
        background: #F0F0F0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        cursor: pointer;
        margin-bottom: 10px;

        .el-icon-arrow-up:before, .el-icon-arrow-down:before {
          font-weight: bold;
          font-size: 14px;
        }

        div {
          color: #333333;
          font-size: 14px;
        }
      }

      .subtotal {
        text-align: center;
      }

      .goodsAttribute {
        text-align: center;
        cursor: pointer;
      }
    }

    .subtotal {
      width: 120px;
    }

    .upLoad {
      width: 90px;
    }

    .orderStatus {
      width: 140px;
    }

    .operation {
      width: 112px;
    }

    .head {
      justify-content: center;
      background: #F0F0F0;
      font-size: 14px;
      color: #333;
      height: 56px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
      position: sticky;
      top: 58px;
      //left: 0;
    }

    .footer {
      background: #F9F9F9;
      min-height: 120px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #333333;
      }
    }

    .footer:last-child {
      //border-radius: 10px 10px 0px 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
    }

    .footer:first-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
    }

    .footer:hover {
      background: #FAF2F2;
    }

    .obody {
      text-align: center;
      height: 70px;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;
    }
  }

  .pagination {
    /deep/ .el-pagination .el-select .el-input {
      width: 110px;
    }
  }
}
</style>
